import PropTypes from "prop-types";
import React, { type ReactNode, type Ref } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import use1DKeyboard from "../hooks/use1DKeyboard";
import useComposeRefs from "../hooks/useComposeRefs";

export type ConfirmDialogProps = {
  affirmativeRef?: Ref<HTMLButtonElement>
  autoFocus?: boolean
  disableAffirmative?: boolean
  cancelButtonText?: string
  confirmButtonText?: string
  content?: ReactNode
  handleAffirmative: () => void
  handleClose: () => void
  fullWidth?: boolean
  loadingAffirmative?: boolean
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false
  open: boolean
  title?: string
}

// Required Props:
// open
// handleClose - This should happen if the cancel button is clicked
// handleAffirmative - This should happen when the confirm button is clicked
// title - What will appear in the dialog title
// content - What will appear in the content
// cancelButtonText - The text used for the cancel button
// confirmButtonText - The text used for the confirm button
// maxWidth - maxWidth prop of Dialog
//
// Optional Props:
// affirmativeRef - the confirm button ref
// disableAffirmative - true if the confirm button should be disabled
// loadingAffirmative - show CircularProgress instead of confirmButtonText
const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { autoFocus = true, disableAffirmative = false, maxWidth = 'md' } = props
  const cancelButtonText = props.cancelButtonText || "No";
  const confirmButtonText = props.confirmButtonText || "Yes";

  const oneDimKeyboardRef = React.useRef();
  const callbackRef = use1DKeyboard({
    element: oneDimKeyboardRef.current,
    initialSelectedIndex: autoFocus && 1,
  });

  const confirmButtonRef = useComposeRefs(
    callbackRef.bind(null, 1),
    props.affirmativeRef
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth={props.fullWidth}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      {props.content && <DialogContent>{props.content}</DialogContent>}
      <DialogActions ref={oneDimKeyboardRef}>
        <Button
          disabled={props.loadingAffirmative}
          onClick={props.handleClose}
          color="secondary"
          ref={callbackRef.bind(null, 0)}
        >
          {cancelButtonText}
        </Button>
        {props.loadingAffirmative ? (
          <CircularProgress size="2rem" />
        ) : (
          <Button
            onClick={props.handleAffirmative}
            color="primary"
            type="submit"
            ref={confirmButtonRef}
            autoFocus={autoFocus}
            disabled={disableAffirmative}
          >
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  autoFocus: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  affirmativeRef: PropTypes.any,
  disableAffirmative: PropTypes.bool,
  handleAffirmative: PropTypes.func,
  loadingAffirmative: PropTypes.bool,
  handleClose: PropTypes.func,
  content: PropTypes.any,
  fullWidth: PropTypes.bool,
  // as const is used to prevent an error in pay-with-check/Dialog.tsx
  maxWidth: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs", false] as const),
  open: PropTypes.bool,
  title: PropTypes.string,
};
